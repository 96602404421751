import { ReactElement } from 'react';

import { composeClasses } from '~utils/commons';

import Typography from '../Typography';

import { AdvantageItemInfoProps } from './types';
import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.advantageItemInfoRoot],
    media: [styles.advantageItemInfoMedia],
    content: [styles.advantageItemInfoContent],
    contentDescriptions: [styles.advantageItemInfoContentDescriptions],
    contentDescriptionsItem: [styles.advantageItemInfoContentDescriptionsItem],
  };

  return composeClasses(slots);
};

const AdvantageItemInfo = ({
  id,
  title,
  img: imgProps,
  descriptions,
}: AdvantageItemInfoProps): ReactElement => {
  const classes = useUtilityClasses();

  return (
    <div className={classes.root}>
      <img {...imgProps} className={classes.media} />

      <div className={classes.content}>
        <Typography
          variant="h2"
          lineHeight="h2"
          fontWeight="regular"
          color="secondary"
        >
          {title}
        </Typography>

        <div className={classes.contentDescriptions}>
          {descriptions.map((description, index) => (
            <div
              key={`advantage-${id}-description-${index}`}
              className={classes.contentDescriptionsItem}
            >
              <Typography
                variant="body2"
                lineHeight="body2"
                fontWeight="bold"
                color="black"
              >
                {description.title}
              </Typography>

              <Typography
                variant="body2"
                lineHeight="body2"
                fontWeight="regular"
                color="black"
              >
                {description.info}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvantageItemInfo;
