// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrappedSpinner__HE1Gw {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: fixed;
  right: 0;
  top: 0px;
  width: 100%;
  z-index: 1000000000;
}

.styles_spinner__Zu60w {
  animation: styles_spin__wHOPr 2s linear infinite;
  border: 16px solid #9F9F9F;
  border-radius: 50%;
  border-top: 16px solid #E67B0A;
  height: 120px;
  width: 120px;
}

@keyframes styles_spin__wHOPr {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/Spinner/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,oCAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,OAAA;EACA,iBAAA;EACA,eAAA;EACA,QAAA;EACA,QAAA;EACA,WAAA;EACA,mBAAA;AADF;;AAIA;EACE,gDAAA;EACA,0BAAA;EACA,kBAAA;EACA,8BAAA;EACA,aAAA;EACA,YAAA;AADF;;AAIA;EAEE;IAAK,uBAAA;EADL;EAGA;IAAO,yBAAA;EAAP;AACF","sourcesContent":["@import \"../../../globals/styles/variables.scss\";\n\n.wrappedSpinner {\n  align-items: center;\n  background-color: rgba($color: #000000, $alpha: .5);\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  left: 0;\n  min-height: 100vh;\n  position: fixed;\n  right: 0;\n  top: 0px;\n  width: 100%;\n  z-index: 1000000000;\n}\n\n.spinner {\n  animation: spin 2s linear infinite;\n  border: 16px solid #9F9F9F;\n  border-radius: 50%;\n  border-top: 16px solid #E67B0A;\n  height: 120px;\n  width: 120px;\n}\n\n@keyframes spin {\n\n  0% { transform: rotate(0deg); }\n\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrappedSpinner": `styles_wrappedSpinner__HE1Gw`,
	"spinner": `styles_spinner__Zu60w`,
	"spin": `styles_spin__wHOPr`
};
export default ___CSS_LOADER_EXPORT___;
