import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.newFooterContainer],
    content: [styles.footerContent],
    utilsContainer: [styles.utilsContainer],
    utilsInfoContainer: [styles.utilsInfoContainer],
    columnContainer: [styles.columnContainer],
    title: [styles.title],
    utilsContactRow: [styles.utilsContactRow],
    columnContainerWithOutSpace: [styles.columnContainerWithOutSpace],
    caption: [styles.caption],
    utilsRrrssContainer: [styles.utilsRrrssContainer],
    divider: [styles.divider],
    columnSmallContainer: [styles.columnSmallContainer],
    containerCenter: [styles.containerCenter],
    legalContainer: [styles.legalContainer],
    afipLink: [styles.afipLink],
    afipImg: [styles.afipImg],
    copyright: [styles.copyright],
    privacyContainer: [styles.privacyContainer],
    privacyLink: [styles.privacyLink],
    socialNetworks: [styles.socialNetworks],
    rrssLink: [styles.rrssLink],
  };

  return composeClasses(slots);
};

const Footer = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <div className={classes.utilsContainer}>
          <div className={classes.utilsInfoContainer}>
            <div className={classes.columnContainer}>
              <h6 className={classes.title}>{t(`footer.contactTitle`)}</h6>

              <div className={classes.utilsContactRow}>
                <div className={classes.columnContainerWithOutSpace}>
                  <span className={classes.caption}>{t(`footer.address`)}</span>
                  <span className={classes.caption}>
                    {t(`footer.kriptonAddress`)}
                  </span>
                </div>

                <div className={classes.columnContainerWithOutSpace}>
                  <span className={classes.caption}>{t(`footer.email`)}</span>
                  <span className={classes.caption}>
                    {t(`footer.kriptonEmail`)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.utilsRrrssContainer}>
            <div className={classes.columnContainer}>
              <h6 className={classes.title}>{t(`footer.rrssTitle`)}</h6>

              <div className={classes.socialNetworks}>
                <a
                  target="_blank"
                  href="https://www.instagram.com/kriptonmarket/"
                  rel="noopener noreferrer"
                  className={classes.rrssLink}
                >
                  <img
                    src={toAbsoluteUrl('/icons/ic_instagram.png')}
                    alt="Instagram"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://www.facebook.com/KriptonMarket"
                  rel="noopener noreferrer"
                  className={classes.rrssLink}
                >
                  <img
                    src={toAbsoluteUrl('/icons/ic_facebook.png')}
                    alt="Facebook"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://twitter.com/Kriptonmarket"
                  rel="noopener noreferrer"
                  className={classes.rrssLink}
                >
                  <img
                    src={toAbsoluteUrl('/icons/ic_twitter.png')}
                    alt="Twitter"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCJuoXX2mlEs2ApdF1OM-JsQ"
                  rel="noopener noreferrer"
                  className={classes.rrssLink}
                >
                  <img
                    src={toAbsoluteUrl('/icons/ic_youtube.png')}
                    alt="YouTube"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/kripton-mkt"
                  rel="noopener noreferrer"
                  className={classes.rrssLink}
                >
                  <img
                    src={toAbsoluteUrl('/icons/ic_linkedin.png')}
                    alt="LinkedIn"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr className={classes.divider} />

        <div
          className={classnames(
            classes.columnSmallContainer,
            classes.containerCenter,
            classes.legalContainer,
          )}
        >
          <a
            href="http://qr.afip.gob.ar/?qr=lY0lzVyyOO0KSusNDTlJpQ,,"
            target="_F960AFIPInfo"
            className={classes.afipLink}
          >
            <img
              src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
              alt="Afip img"
              className={classes.afipImg}
            />
          </a>

          <div
            className={classnames(
              classes.columnSmallContainer,
              classes.containerCenter,
            )}
          >
            <img src={toAbsoluteUrl('/icons/km_logo.png')} alt="KM Logo" />

            <span className={classnames(classes.copyright, classes.caption)}>
              {t(`footer.copyRight`)}
            </span>
          </div>

          <div className={classes.privacyContainer}>
            <a
              href={`https://cash.kriptonmarket.com/privacy`}
              target="_blank"
              rel="noopener noreferrer"
              className={classnames(classes.privacyLink, classes.caption)}
            >
              {t(`footer.privacy`)}
            </a>

            <a
              href="https://kriptonmarket.com/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className={classnames(classes.privacyLink, classes.caption)}
            >
              {t(`footer.terms`)}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
