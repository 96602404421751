import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const isProduction = process.env.NODE_ENV === 'production';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !isProduction,
    load: 'languageOnly',
    fallbackLng: 'pt',
    interpolation: { escapeValue: false },
    react: { useSuspense: true },
    detection: {
      order: [
        'path',
        'subdomain',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
      ],
    },
  });

export default i18n;
