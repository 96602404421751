import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.aboutKriptoSectionRoot],
    card: [styles.aboutKriptoSectionCard],
    cardMedia: [styles.aboutKriptoSectionCardMedia],
    cardMediaPrimary: [styles.aboutKriptoSectionCardMediaPrimary],
    cardMediaSecondary: [styles.aboutKriptoSectionCardMediaSecondary],
    cardMediaImg: [styles.aboutKriptoSectionCardMediaImg],
    cardMediaContent: [styles.aboutKriptoSectionCardContent],
    cardMediaContentTypography: [
      styles.aboutKriptoSectionCardContentTypography,
    ],
  };

  return composeClasses(slots);
};

const AboutKriptoSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <div className={classes.card}>
        <div
          className={classnames(classes.cardMedia, classes.cardMediaPrimary)}
        >
          <img
            src={toAbsoluteUrl('/icons/btc-3d.png')}
            alt="BTC - 3D"
            className={classes.cardMediaImg}
          />
        </div>

        <div className={classes.cardMediaContent}>
          <Typography
            variant="h4"
            lineHeight="h4"
            fontWeight="semiBold"
            color="primary"
            className={classes.cardMediaContentTypography}
          >
            {t('aboutKripto.btc.title')}
          </Typography>

          <Typography
            variant="caption"
            lineHeight="caption"
            fontWeight="regular"
            color="black"
            className={classes.cardMediaContentTypography}
          >
            {t('aboutKripto.btc.info')}
          </Typography>
        </div>
      </div>

      <div className={classes.card}>
        <div className={classes.cardMediaContent}>
          <Typography
            variant="h4"
            lineHeight="h4"
            fontWeight="semiBold"
            color="terciary"
            className={classes.cardMediaContentTypography}
          >
            {t('aboutKripto.usdt.title')}
          </Typography>

          <Typography
            variant="caption"
            lineHeight="caption"
            fontWeight="regular"
            color="black"
            className={classes.cardMediaContentTypography}
          >
            {t('aboutKripto.usdt.info')}
          </Typography>
        </div>

        <div
          className={classnames(classes.cardMedia, classes.cardMediaSecondary)}
        >
          <img
            src={toAbsoluteUrl('/icons/usdt-3d.png')}
            alt="USDT - 3D"
            className={classes.cardMediaImg}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutKriptoSection;
