import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { composeClasses } from '~utils/commons';

import Typography from '../Typography';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.shopsSectionRoot],
    iframeMap: [styles.shopsSectionIframeMap],
  };

  return composeClasses(slots);
};

const centerBariloche = {
  lon: -71.3052598,
  lat: -41.1354052,
};

// eslint-disable-next-line max-len
const urlIframeMap = `https://map.kriptonmarket.com?only_map=true&lon=${centerBariloche.lon}&lat=${centerBariloche.lat}`;

const ShopsSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <Typography
        variant="h2"
        lineHeight="h2"
        fontWeight="regular"
        color="secondary"
        align="center"
      >
        {t('shops.title')}
      </Typography>

      <iframe
        title="Map Shops"
        src={urlIframeMap}
        className={classes.iframeMap}
        scrolling="no"
        frameBorder="0"
        allowFullScreen
      />
    </section>
  );
};

export default ShopsSection;
