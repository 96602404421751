import { ReactElement } from 'react';

import {
  Container,
  Hero,
  DescriptionSection,
  ShopsSection,
  AboutKriptoSection,
  AdvantagesSection,
  DetailsSection,
  Footer,
} from '~components/index';

const App = (): ReactElement => (
  <Container>
    <Hero />

    <DescriptionSection />

    <ShopsSection />

    <AboutKriptoSection />

    <AdvantagesSection />

    <DetailsSection />

    <Footer />
  </Container>
);

export default App;
