// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_descriptionSectionRoot__vuY\\+7 {
  align-items: center;
  background: radial-gradient(150.88% 140.35% at 100% 97.35%, #08AB83 0%, #009292 18%, #057A83 28%, #113E5D 49%, #18214C 58%, #000004 91%);
  display: flex;
  flex-direction: column;
  height: 774px;
  justify-content: space-between;
  padding: 94px 138px;
}
.styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionInfoTypography__i0Y0J {
  padding: 0 62px;
}
.styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 {
  display: flex;
  gap: 49px;
  align-items: center;
}
.styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 .styles_descriptionSectionLogosBtc__q5XeS {
  height: 178px;
  width: 130px;
}
.styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 .styles_descriptionSectionLogosUsdt__L1U\\+S {
  height: 162px;
  width: 131px;
}
.styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 .styles_descriptionSectionLogosPix__X0yyP {
  height: 106px;
  width: 295px;
}

@media screen and (max-width: 768px) {
  .styles_descriptionSectionRoot__vuY\\+7 {
    height: 577px;
    padding: 51px 22px;
  }
  .styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionInfoTypography__i0Y0J {
    padding: 0;
  }
  .styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 {
    gap: 26px;
  }
  .styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 .styles_descriptionSectionLogosBtc__q5XeS {
    height: 78px;
    width: 57px;
  }
  .styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 .styles_descriptionSectionLogosUsdt__L1U\\+S {
    height: 78px;
    width: 57px;
  }
  .styles_descriptionSectionRoot__vuY\\+7 .styles_descriptionSectionLogos__1ZWd9 .styles_descriptionSectionLogosPix__X0yyP {
    height: 46px;
    width: 129px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/DescriptionSection/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,wIAAA;EASA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AATF;AAWE;EACE,eAAA;AATJ;AAYE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAVJ;AAYI;EACE,aAAA;EACA,YAAA;AAVN;AAaI;EACE,aAAA;EACA,YAAA;AAXN;AAcI;EACE,aAAA;EACA,YAAA;AAZN;;AAiBA;EACE;IACE,aAAA;IACA,kBAAA;EAdF;EAgBE;IACE,UAAA;EAdJ;EAiBE;IACE,SAAA;EAfJ;EAiBI;IACE,YAAA;IACA,WAAA;EAfN;EAkBI;IACE,YAAA;IACA,WAAA;EAhBN;EAmBI;IACE,YAAA;IACA,YAAA;EAjBN;AACF","sourcesContent":["@import \"../../../globals/styles/variables.scss\";\n\n.descriptionSectionRoot {\n  align-items: center;\n  background: radial-gradient(\n    150.88% 140.35% at 100.00% 97.35%,\n    #08AB83 0%,\n    #009292 18.00%,\n    #057A83 28.00%,\n    #113E5D 49.00%,\n    #18214C 58.00%,\n    #000004 91.00%,\n  );\n  display: flex;\n  flex-direction: column;\n  height: 774px;\n  justify-content: space-between;\n  padding: 94px 138px;\n\n  .descriptionSectionInfoTypography {\n    padding: 0 62px;\n  }\n\n  .descriptionSectionLogos {\n    display: flex;\n    gap: 49px;\n    align-items: center;\n\n    .descriptionSectionLogosBtc {\n      height: 178px;\n      width: 130px;\n    }\n\n    .descriptionSectionLogosUsdt {\n      height: 162px;\n      width: 131px;\n    }\n\n    .descriptionSectionLogosPix {\n      height: 106px;\n      width: 295px;\n    }\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .descriptionSectionRoot {\n    height: 577px;\n    padding: 51px 22px;\n\n    .descriptionSectionInfoTypography {\n      padding: 0;\n    }\n\n    .descriptionSectionLogos {\n      gap: 26px;\n\n      .descriptionSectionLogosBtc {\n        height: 78px;\n        width: 57px;\n      }\n\n      .descriptionSectionLogosUsdt {\n        height: 78px;\n        width: 57px;\n      }\n  \n      .descriptionSectionLogosPix {\n        height: 46px;\n        width: 129px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionSectionRoot": `styles_descriptionSectionRoot__vuY+7`,
	"descriptionSectionInfoTypography": `styles_descriptionSectionInfoTypography__i0Y0J`,
	"descriptionSectionLogos": `styles_descriptionSectionLogos__1ZWd9`,
	"descriptionSectionLogosBtc": `styles_descriptionSectionLogosBtc__q5XeS`,
	"descriptionSectionLogosUsdt": `styles_descriptionSectionLogosUsdt__L1U+S`,
	"descriptionSectionLogosPix": `styles_descriptionSectionLogosPix__X0yyP`
};
export default ___CSS_LOADER_EXPORT___;
