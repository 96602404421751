// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_shopsSectionRoot__a0dZR {
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  gap: 98px;
  padding: 97px 175px;
}
.styles_shopsSectionRoot__a0dZR .styles_shopsSectionIframeMap__BCIe3 {
  height: 398px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .styles_shopsSectionRoot__a0dZR {
    gap: 20px;
    padding: 60px 22px;
  }
  .styles_shopsSectionIframeMap__BCIe3 {
    height: 353px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/ShopsSection/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AADF;AAGE;EACE,aAAA;EACA,WAAA;AADJ;;AAKA;EACE;IACE,SAAA;IACA,kBAAA;EAFF;EAKA;IACE,aAAA;EAHF;AACF","sourcesContent":["@import \"../../../globals/styles/variables.scss\";\n\n.shopsSectionRoot {\n  background-color: #F8F8F8;\n  display: flex;\n  flex-direction: column;\n  gap: 98px;\n  padding: 97px 175px;\n\n  .shopsSectionIframeMap {\n    height: 398px;\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .shopsSectionRoot {\n    gap: 20px;\n    padding: 60px 22px;\n  }\n\n  .shopsSectionIframeMap {\n    height: 353px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shopsSectionRoot": `styles_shopsSectionRoot__a0dZR`,
	"shopsSectionIframeMap": `styles_shopsSectionIframeMap__BCIe3`
};
export default ___CSS_LOADER_EXPORT___;
