import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.descriptionSectionRoot],
    infoTypography: [styles.descriptionSectionInfoTypography],
    logos: [styles.descriptionSectionLogos],
    logoBtc: [styles.descriptionSectionLogosBtc],
    logoUsdt: [styles.descriptionSectionLogosUsdt],
    logoPix: [styles.descriptionSectionLogosPix],
  };

  return composeClasses(slots);
};

const DescriptionSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <Typography
        variant="h2"
        lineHeight="h2"
        fontWeight="regular"
        color="white"
        align="center"
      >
        {t('description.title')}
      </Typography>

      <Typography
        variant="body1"
        lineHeight="body1"
        fontWeight="regular"
        color="white"
        align="center"
        className={classes.infoTypography}
      >
        {t('description.info')}
      </Typography>

      <div className={classes.logos}>
        <SVG
          src={toAbsoluteUrl('/icons/btc-ln.svg')}
          title="BTC - LN"
          className={classes.logoBtc}
        />

        <SVG
          src={toAbsoluteUrl('/icons/usdt.svg')}
          title="USDT - Tether"
          className={classes.logoUsdt}
        />

        <img
          src={toAbsoluteUrl('/icons/pix-bc.png')}
          alt="Pix - BC"
          className={classes.logoPix}
        />
      </div>
    </section>
  );
};

export default DescriptionSection;
