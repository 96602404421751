import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.heroRoot],
    img: [styles.heroImg],
    content: [styles.heroContent],
    contentWelcome: [styles.heroContentWelcome],
    contentWelcomeTypography: [styles.heroContentWelcomeTypography],
    contentDescription: [styles.heroContentDescription],
    contentDescriptionIcon: [styles.heroContentDescriptionIcon],
    contetFooter: [styles.heroContentFooter],
    contetFooterKmLogo: [styles.heroContentFooterKmLogo],
    contetFooterTetherLogo: [styles.heroContentFooterTetherLogo],
  };

  return composeClasses(slots);
};

const Hero = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <img
        src={toAbsoluteUrl('/images/hero.png')}
        alt="Hero"
        className={classes.img}
      />

      <div className={classes.content}>
        <div className={classes.contentWelcome}>
          <Typography
            variant="h1"
            lineHeight="h1"
            fontWeight="black"
            color="white"
            align="center"
            className={classes.contentWelcomeTypography}
          >
            {t('hero.welcome')}
          </Typography>

          <div className={classes.contentDescription}>
            <Typography
              variant="h2"
              lineHeight="h2"
              fontWeight="regular"
              color="white"
              align="center"
            >
              {t('hero.description')}

              <SVG
                src={toAbsoluteUrl('/icons/btc.svg')}
                title="Tether"
                className={classes.contentDescriptionIcon}
              />

              {t('hero.and')}

              <SVG
                src={toAbsoluteUrl('/icons/tether.svg')}
                title="BTC"
                className={classes.contentDescriptionIcon}
              />
            </Typography>
          </div>
        </div>

        <div className={classes.contetFooter}>
          <SVG
            src={toAbsoluteUrl('/icons/km-isologo.svg')}
            title="KM Isologo"
            className={classes.contetFooterKmLogo}
          />

          <SVG
            src={toAbsoluteUrl('/icons/tether-isologo.svg')}
            title="Tether Isologo"
            className={classes.contetFooterTetherLogo}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
