import { ReactElement } from 'react';
import { upperFirst } from 'lodash';
import classnames from 'classnames';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import { DetailsCardProps } from './types';
import styles from './styles.module.scss';

const useUtilityClasses = ({ degrade }: Pick<DetailsCardProps, 'degrade'>) => {
  const slots = {
    root: [
      styles.detailsCardRoot,
      styles[`detailsCardRootDegrade${upperFirst(degrade)}`],
    ],
    bgImg: [styles.detailsCardBgImg],
    content: [styles.detailsCardContent],
  };

  return composeClasses(slots);
};

const DetailsCard = ({
  degrade,
  className,
  children,
}: DetailsCardProps): ReactElement => {
  const classes = useUtilityClasses({ degrade });

  return (
    <div className={classnames(classes.root, className)}>
      <img
        src={toAbsoluteUrl('/images/bg-mountains.png')}
        alt="Background Mountains"
        className={classes.bgImg}
      />

      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default DetailsCard;
