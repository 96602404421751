// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_advantageItemInfoRoot__q7Toh {
  align-items: center;
  display: flex;
  gap: 138px;
}
.styles_advantageItemInfoRoot__q7Toh:nth-child(odd) {
  flex-direction: row;
}
.styles_advantageItemInfoRoot__q7Toh:nth-child(even) {
  flex-direction: row-reverse;
}
.styles_advantageItemInfoRoot__q7Toh .styles_advantageItemInfoMedia__iQxK8 {
  height: 603px;
  max-width: 454px;
  width: 100%;
}
.styles_advantageItemInfoRoot__q7Toh .styles_advantageItemInfoContent__DKp2I {
  display: flex;
  flex-direction: column;
  gap: 38px;
  text-align: left;
}
.styles_advantageItemInfoRoot__q7Toh .styles_advantageItemInfoContent__DKp2I .styles_advantageItemInfoContentDescriptions__eKCIQ {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.styles_advantageItemInfoRoot__q7Toh .styles_advantageItemInfoContent__DKp2I .styles_advantageItemInfoContentDescriptions__eKCIQ .styles_advantageItemInfoContentDescriptionsItem__PaLxQ {
  display: flex;
  flex-direction: column;
  gap: 0;
}

@media screen and (max-width: 1024px) {
  .styles_advantageItemInfoRoot__q7Toh {
    gap: 35px;
  }
  .styles_advantageItemInfoRoot__q7Toh:nth-child(odd), .styles_advantageItemInfoRoot__q7Toh:nth-child(even) {
    flex-direction: column;
  }
  .styles_advantageItemInfoRoot__q7Toh .styles_advantageItemInfoContent__DKp2I {
    gap: 25px;
    text-align: center;
  }
  .styles_advantageItemInfoRoot__q7Toh .styles_advantageItemInfoContent__DKp2I .styles_advantageItemInfoContentDescriptions__eKCIQ .styles_advantageItemInfoContentDescriptionsItem__PaLxQ {
    gap: 18px;
  }
}
@media screen and (max-width: 768px) {
  .styles_advantageItemInfoRoot__q7Toh .styles_advantageItemInfoMedia__iQxK8 {
    height: 449px;
    max-width: 338px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/AdvantageItemInfo/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,aAAA;EACA,UAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,2BAAA;AAFJ;AAKE;EACE,aAAA;EACA,gBAAA;EACA,WAAA;AAHJ;AAME;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AAJJ;AAMI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAJN;AAMM;EACE,aAAA;EACA,sBAAA;EACA,MAAA;AAJR;;AAUA;EACE;IACE,SAAA;EAPF;EASE;IAEE,sBAAA;EARJ;EAWE;IACE,SAAA;IACA,kBAAA;EATJ;EAYM;IACE,SAAA;EAVR;AACF;AAgBA;EAEI;IACE,aAAA;IACA,gBAAA;EAfJ;AACF","sourcesContent":["@import \"../../../globals/styles/variables.scss\";\n\n.advantageItemInfoRoot {\n  align-items: center;\n  display: flex;\n  gap: 138px;\n\n  &:nth-child(odd) {\n    flex-direction: row;\n  }\n\n  &:nth-child(even) {\n    flex-direction: row-reverse;\n  }\n\n  .advantageItemInfoMedia {\n    height: 603px;\n    max-width: 454px;\n    width: 100%;\n  }\n\n  .advantageItemInfoContent {\n    display: flex;\n    flex-direction: column;\n    gap: 38px;\n    text-align: left;\n\n    .advantageItemInfoContentDescriptions {\n      display: flex;\n      flex-direction: column;\n      gap: 18px;\n\n      .advantageItemInfoContentDescriptionsItem {\n        display: flex;\n        flex-direction: column;\n        gap: 0;\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .advantageItemInfoRoot {\n    gap: 35px;\n\n    &:nth-child(odd),\n    &:nth-child(even) {\n      flex-direction: column;\n    }\n\n    .advantageItemInfoContent {\n      gap: 25px;\n      text-align: center;\n\n      .advantageItemInfoContentDescriptions {\n        .advantageItemInfoContentDescriptionsItem {\n          gap: 18px;\n        }\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .advantageItemInfoRoot {\n    .advantageItemInfoMedia {\n      height: 449px;\n      max-width: 338px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advantageItemInfoRoot": `styles_advantageItemInfoRoot__q7Toh`,
	"advantageItemInfoMedia": `styles_advantageItemInfoMedia__iQxK8`,
	"advantageItemInfoContent": `styles_advantageItemInfoContent__DKp2I`,
	"advantageItemInfoContentDescriptions": `styles_advantageItemInfoContentDescriptions__eKCIQ`,
	"advantageItemInfoContentDescriptionsItem": `styles_advantageItemInfoContentDescriptionsItem__PaLxQ`
};
export default ___CSS_LOADER_EXPORT___;
