import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';
import AdvantageItemInfo from '../AdvantageItemInfo';

import { AdvantageList } from './types';
import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.advantagesSectionRoot],
    header: [styles.advantagesSectionHeader],
    headerImg: [styles.advantagesSectionHeaderImg],
    headerContent: [styles.advantagesSectionHeaderContent],
    list: [styles.advantagesSectionList],
  };

  return composeClasses(slots);
};

const AdvantagesSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  const advantagesListDictionary = useMemo(
    () =>
      t<string, AdvantageList>('advantages.list', {
        returnObjects: true,
        defaultValue: {},
      }),
    [t],
  );

  return (
    <section className={classes.root}>
      <div className={classes.header}>
        <img
          src={toAbsoluteUrl('/images/advantages-header.png')}
          alt="Advantages Header"
          className={classes.headerImg}
        />

        <div className={classes.headerContent}>
          <Typography
            variant="h2"
            lineHeight="h2"
            fontWeight="regular"
            color="white"
            align="center"
          >
            {t('advantages.title')}
          </Typography>

          <Typography
            variant="body2"
            lineHeight="body2"
            fontWeight="regular"
            color="white"
            align="center"
          >
            {t('advantages.info')}
          </Typography>
        </div>
      </div>

      <div className={classes.list}>
        {Object.entries(advantagesListDictionary).map(
          ([advantageKey, advantageValue]) => (
            <AdvantageItemInfo
              key={`advantage-${advantageKey}`}
              id={advantageKey}
              title={advantageValue.title}
              img={{
                src: toAbsoluteUrl(`/images/advantages/${advantageKey}.png`),
                alt: advantageValue.title,
              }}
              descriptions={advantageValue.descriptions}
            />
          ),
        )}
      </div>
    </section>
  );
};

export default AdvantagesSection;
