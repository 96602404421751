import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import DetailsCard from '../DetailsCard';
import Typography from '../Typography';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.detailsSectionRoot],
    columnCard: [styles.detailsSectionColumnCard],
    rowCard: [styles.detailsSectionRowCard],
    rowCardTitleWithIcon: [styles.detailsSectionRowCardTitleWithIcon],
    rowCardTitleWithIconImg: [styles.detailsSectionRowCardTitleWithIconImg],
    swapixLogo: [styles.detailsSectionSwapixLogo],
    polygonBg: [styles.detailsSectionBgPolygon],
  };

  return composeClasses(slots);
};

const DetailsSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <div className={classes.rowCard}>
        <DetailsCard degrade="primary">
          <Typography
            variant="h3"
            lineHeight="h3"
            fontWeight="bold"
            color="white"
          >
            {t('details.node.title')}
          </Typography>

          <div className={classes.columnCard}>
            <Typography
              variant="caption"
              lineHeight="caption"
              fontWeight="regular"
              color="white"
            >
              {t('details.node.info')}
            </Typography>

            <Typography
              variant="caption"
              lineHeight="caption"
              fontWeight="light"
              color="white"
            >
              {t('details.node.schedule')}
            </Typography>

            <Typography
              variant="caption"
              lineHeight="caption"
              fontWeight="light"
              color="white"
            >
              {t('details.node.contact')}
            </Typography>
          </div>
        </DetailsCard>

        <DetailsCard degrade="primary">
          <Typography
            variant="h3"
            lineHeight="h3"
            fontWeight="bold"
            color="white"
          >
            {t('details.merchant.title')}
          </Typography>

          <div className={classes.columnCard}>
            <Typography
              variant="caption"
              lineHeight="caption"
              fontWeight="regular"
              color="white"
            >
              {t('details.merchant.info')}
            </Typography>

            <Typography
              variant="caption"
              lineHeight="caption"
              fontWeight="light"
              color="white"
            >
              {t('details.merchant.contact')}
            </Typography>
          </div>
        </DetailsCard>
      </div>

      <DetailsCard degrade="secondary">
        <div className={classes.rowCardTitleWithIcon}>
          <Typography
            variant="h3"
            lineHeight="h3"
            fontWeight="bold"
            color="white"
          >
            {t('details.pix.title')}
          </Typography>

          <img
            src={toAbsoluteUrl('/icons/pix-bc.png')}
            alt="Pix - BC"
            className={classes.rowCardTitleWithIconImg}
          />
        </div>

        <Typography
          variant="caption"
          lineHeight="caption"
          fontWeight="regular"
          color="white"
          align="center"
        >
          {t('details.pix.info')}
        </Typography>
      </DetailsCard>

      <DetailsCard degrade="secondary">
        <SVG
          src={toAbsoluteUrl('/icons/swapix.svg')}
          title="Swapix"
          className={classes.swapixLogo}
        />

        <Typography
          variant="h3"
          lineHeight="h3"
          fontWeight="bold"
          color="white"
          align="center"
        >
          {t('details.swapix.title')}
        </Typography>

        <Typography
          variant="caption"
          lineHeight="caption"
          fontWeight="regular"
          color="white"
          align="center"
        >
          {t('details.swapix.info')}
        </Typography>
      </DetailsCard>

      <SVG
        src={toAbsoluteUrl('/images/bg-polygon.svg')}
        title="Background Polygon"
        className={classes.polygonBg}
      />
    </section>
  );
};

export default DetailsSection;
