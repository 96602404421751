export { default as AboutKriptoSection } from './AboutKriptoSection';
export { default as AdvantagesSection } from './AdvantagesSection';
export { default as Container } from './Container';
export { default as DescriptionSection } from './DescriptionSection';
export { default as DetailsCard } from './DetailsCard';
export { default as DetailsSection } from './DetailsSection';
export { default as Footer } from './Footer';
export { default as Hero } from './Hero';
export { default as ShopsSection } from './ShopsSection';
export { default as Spinner } from './Spinner';
export { default as Typography } from './Typography';
